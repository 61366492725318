import { toInteger, toString, get } from "lodash/fp";
import { FieldMap } from "../types/field-map";
import { IDiscount } from "../types";
import dayjs from "dayjs";

const FIELD_MAPPING: FieldMap[] = [
  {
    key: "id",
    field: "id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "name",
    field: "name",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "description",
    field: "description",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "value",
    field: "value",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "valueType",
    field: "value_type",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "startDate",
    field: "start_date",
    transform: {
      response: (value: any) => dayjs(value),
      payload: (value: any) => dayjs(value).format("YYYY-MM-DD"),
    },
    render: (value: any) => dayjs(value).format("MMM DD, YYYY"),
  },
  {
    key: "endDate",
    field: "end_date",
    transform: {
      response: (value: any) => dayjs(value),
      payload: (value: any) => dayjs(value).format("YYYY-MM-DD"),
    },
    render: (value: any) => dayjs(value).format("MMM DD, YYYY"),
  },
  {
    key: "qualifiedCount",
    field: "qualified_count",
    transform: { response: (value: any) => toInteger(value) },
  },
  {
    key: "availableSlot",
    field: "available_slot",
    transform: { response: (value: any) => toInteger(value) },
  },
];

export const parseData = (json: any): IDiscount => {
  return FIELD_MAPPING.reduce((acc: IDiscount, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted };
  }, {} as IDiscount);
};

export const parsePayload = (json: any) => {
  return FIELD_MAPPING.reduce((acc, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const value = get(key, json);
    return {
      ...acc,
      ...(typeof value != "undefined" && {
        [typeof field === "string" ? field : field.payload]: transform?.payload
          ? transform.payload(value)
          : value,
      }),
    };
  }, {});
};

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden).map(
  (field) => ({ column: field.key, path: field.path || field.key, render: field.render })
);
