import {
  get,
  map,
  toInteger,
  toString
} from "lodash/fp";
import request, { isAxiosError } from "../../utils/axios";
import {
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit";
import { FieldMap } from "../../types/field-map";
import dayjs from "dayjs";
import { ResponseStatus } from "../../constants";

export interface ICustomer {
  id: number;
  user: number;
  customerType: number;
  customerStatus: number;
  companyName: string;
  address: string;
  country: number;
  province: number;
  city: number;
  barangay: number;
  zipcode: string;
  paymentType: number;
  paymentTerm: number;
  hasTaxExemptions: number;
  taxExemptPercentage: number;
  serviceAdvisor: number;
  vehicles: number[];
  shops: number[];
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: string;
  updatedBy: string;
  deletedBy: string;
}
interface ICustomerPayload {
  id?: number;
  user_id: number;
  customer_type_id: number;
  customer_status_id: number;
  company_name: string;
  address: string
  country_id: number;
  province_id: number;
  city_id: number;
  barangay_id: number;
  zipcode: string;
  payment_type_id: number;
  payment_term_id: number;
  has_tax_exemptions: number;
  tax_exempt_percentage: number;
  advisor_id: number;
  vehicles: number[];
  shops: number[];
  created_by?: number;
  updated_by?: number;
  created_at?: string; //YYYY-MM-DD HH:mm:ss
  updated_at?: string; //YYYY-MM-DD HH:mm:ss
}

const FIELD_MAPPING: FieldMap[] = [{
  key: "id",
  field: "id",
  transform: { response: (value: any) => toInteger(value) },
}, {
  key: "user",
  field: { response: "user", payload: "user_id" },
  render: (value: any) => `${toString(value.first_name)} ${toString(value.last_name)}`
}, {
  key: "customerType",
  field: { response: "customer_type", payload: "customer_type_id" },
  render: (value: any) => toString(value?.name)
}, {
  key: "customerStatus",
  field: { response: "customer_status", payload: "customer_status_id" },
  render: (value: any) => toString(value?.name)
}, {
  key: "companyName",
  field: "company_name",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "address",
  field: "address",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "country",
  field: { response: "country", payload: "country_id" },
  render: (value: any) => toString(value?.name)
}, {
  key: "province",
  field: { response: "province", payload: "province_id" },
  render: (value: any) => toString(value?.name)
}, {
  key: "city",
  field: { response: "city", payload: "city_id" },
  render: (value: any) => toString(value?.name)
}, {
  key: "barangay",
  field: { response: "barangay", payload: "barangay_id" },
  render: (value: any) => toString(value?.name)
}, {
  key: "zipcode",
  field: "zipcode",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "paymentType",
  field: { response: "payment_type", payload: "payment_type_id" },
  render: (value: any) => toString(value?.name)
}, {
  key: "paymentTerm",
  field: { response: "payment_term", payload: "payment_term_id" },
  render: (value: any) => toString(value?.name)
}, {
  key: "hasTaxExemptions",
  field: "has_tax_exemptions",
  transform: { response: (value: any) => toInteger(value) },
}, {
  key: "taxExemptPercentage",
  field: "tax_exempt_percentage",
  transform: { response: (value: any) => toInteger(value) },
}, {
  key: "serviceAdvisor",
  field: { response: "latest_service_advisor_id", payload: "advisor_id" },
  transform: { response: (value: any, raw: any) => ({ id: value, name: raw.latest_service_advisor }) },
  render: (value: any) => toString(value?.name)
}, {
  key: "vehicles",
  field: "vehicles",
  render: (value: any) => value.map((v: any) => v.plate_no).join(',')
}, {
  key: "shops",
  field: "shops",
  render: (value: any) => value.map((v: any) => v.name).join(',')
}, {
  key: "createdAt",
  field: "created_at",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "createdBy",
  field: "created_by",
  transform: { response: (value: any) => toString(value) },
  hidden: true,
}, {
  key: "deletedAt",
  field: "deleted_at",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "deletedBy",
  field: "deleted_by",
  transform: { response: (value: any) => toString(value) },
  hidden: true,
}, {
  key: "updatedAt",
  field: "updated_at",
  transform: { response: (value: any) => toString(value) },
}, {
  key: "updatedBy",
  field: "updated_by",
  transform: { response: (value: any) => toString(value) },
}];

interface IError {
  message: string;
  status: number;
}

interface IParams {
  search?: string | null;
  per_page?: number;
  page?: number;
}

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden)
  .map(field => ({ column: field.key, path: field.path || field.key, render: field.render }));

export const parseData = (json: any): ICustomer => {
  return FIELD_MAPPING.reduce((acc: ICustomer, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw, json) : raw;
    return { ...acc, [key]: formatted };
  }, {} as ICustomer);
};

const parsePayload = (json: any): ICustomerPayload => {
  return FIELD_MAPPING.reduce((acc: ICustomerPayload, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const value = get(key, json);
    return {
      ...acc,
      ...(typeof value != "undefined" && {
        [typeof field === "string" ? field : field.payload]: transform?.payload
          ? transform.payload(value, json)
          : value,
      }),
    };
  }, {} as ICustomerPayload);
};

export const fetch = createAsyncThunk<
  { customers: ICustomer[]; count: number }, IParams | undefined, { rejectValue: IError }>(
    "customer/fetch",
    async (params: IParams | undefined, { rejectWithValue }) => {
      try {
        const { data } = await request.get("api/customers", { params });
        return { customers: map(parseData, data.data), count: data.total };
      } catch (error: any) {
        if (isAxiosError(error)) {
          const { response: { data, status } } = error as {
            response: { data: any; status: number }
          };
          return rejectWithValue({ message: data?.message, status });
        } else {
          return rejectWithValue({ message: error.message, status: 0 });
        }
      }
    });


export const create = createAsyncThunk<ICustomer, ICustomer, { rejectValue: IError }>(
  "customer/create",
  async (args: ICustomer, { rejectWithValue }) => {
    try {
      const payload: ICustomerPayload = parsePayload(args);
      const { data } = await request.post("api/customers", { ...payload, customer_status_id: 1, created_at: dayjs().format('YYYY-MM-DD HH:mm:ss') });
      if ([ResponseStatus.ERROR, ResponseStatus.FAILED].includes(data.data.status)) throw data.data.error;
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const update = createAsyncThunk<ICustomer, ICustomer, { rejectValue: IError }>(
  'customer/update',
  async (payload: ICustomer, { rejectWithValue }) => {
    try {
      const { data } = await request.put('api/customers/update', { ...parsePayload(payload), updated_at: dayjs().format('YYYY-MM-DD HH:mm:ss') });
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const view = createAsyncThunk<ICustomer, number, { rejectValue: IError }>(
  'customer/view',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`api/customers/${id}`);
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const remove = createAsyncThunk<ICustomer, number, { rejectValue: IError }>(
  'customer/remove',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await request.delete(`api/customers/${id}`);
      return parseData(data.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error as {
          response: { data: any; status: number }
        };
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

type CustomerState = {
  count: number;
  collection: ICustomer[];
  status: "loading" | "idle" | "created" | "updated" | "deleted";
  error: string | null;
};

const initialState: CustomerState = {
  count: 0,
  collection: [],
  status: "idle",
  error: null,
};
export const customerSlice = createSlice({
  name: "customer",
  initialState: { ...initialState },
  reducers: {
    reset: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetch.fulfilled, (state, { payload }) => {
      state.collection = payload.customers;
      state.count = payload.count;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetch.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(create.pending, state => {
      console.log(state)
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(create.fulfilled, (state) => {
      state.status = "created";
      state.error = null;
    });
    builder.addCase(create.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(update.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(update.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(remove.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(remove.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(remove.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
    builder.addCase(view.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(view.fulfilled, (state) => {
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(view.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  },
});

export const { reset } = customerSlice.actions;

export default customerSlice.reducer;
