import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { DrawerHeader } from "./Drawer";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

interface LayoutProps extends React.PropsWithChildren {
  type?: string;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const drawerWidth = 300;
  const [open, setOpen] = React.useState(true);

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar
        handleMenuToggle={() => setOpen(!open)}
        AppBarProps={{
          width: drawerWidth,
          elevation: 0,
          color: "default",
          position: "fixed",
          open: open,
        }}
      />
      <Sidebar width={drawerWidth} open={open} />
      <Box
        component="main"
        display="flex"
        flexDirection="column"
        sx={{
          height: "100vh",
          overflow: "hidden",
          flexGrow: 1,
          p: 3,
          backgroundColor: (theme) => theme.palette.common.bg_secondary,
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  type: PropTypes.string,
};

export default Layout;
