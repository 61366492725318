import { get, toInteger, toString } from "lodash/fp";
import { FieldMap } from "../types/field-map";
import { IEstimate } from "../types";

const FIELD_MAPPING: FieldMap[] = [
  {
    key: "id",
    field: "id",
    transform: { response: (value: any) => toInteger(value) },
  },
  {
    key: "description",
    field: "description",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "grandTotal",
    field: "grand_total",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "laborTotalAmount",
    field: "labor_total_amount",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "partsTotalAmount",
    field: "parts_total_amount",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "discountTotalAmount",
    field: "discount_total_amount",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "hasTaxExemptions",
    field: "has_tax_exemptions",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "taxExemptPercentage",
    field: "tax_exempt_percentage",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "taxTotalAmount",
    field: "tax_total_amount",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "startDate",
    field: "start_date",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "endDate",
    field: "end_date",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "startTime",
    field: "start_time",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "endTime",
    field: "end_time",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "customerId",
    field: "customer_id",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "vehicleId",
    field: "vehicle_id",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "serviceLocationTypeId",
    field: "service_location_type_id",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "shopId",
    field: "shop_id",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "zipcode",
    field: "zipcode",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "address",
    field: "address",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "country",
    field: "country",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "province",
    field: "province",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "city",
    field: "city",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "barangay",
    field: "barangay",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "latestStatusId",
    field: "latest_status_id",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "latestStatus",
    field: "latest_status",
    transform: { response: (value: any) => toString(value) },
  },
];

export const parseData = (json: any): IEstimate => {
  return FIELD_MAPPING.reduce((acc: IEstimate, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted };
  }, {} as IEstimate);
};

export const parsePayload = (json: any) => {
  return FIELD_MAPPING.reduce((acc, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const value = get(key, json);
    return {
      ...acc,
      ...(typeof value != "undefined" && {
        [typeof field === "string" ? field : field.payload]: transform?.payload
          ? transform.payload(value)
          : value,
      }),
    };
  }, {});
}

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden).map(
  (field) => ({ column: field.key, path: field.path || field.key })
);
