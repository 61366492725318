import { combineReducers } from "redux";
import usersReducer from "./usersSlice";
import permissionReducer from "./permissionsSlice";
import rolesReducer from "./rolesSlice";
import menusReducer from "./menusSlice";
import addressReducer from "./addressSlice";
import paymentTermsReducer from "./paymentTermsSlice";
import paymentTypesReducer from "./paymentTypesSlice";
import customersReducer from "./customers";
import optionReducer from "./optionSlice";
import estimateReducer from "./estimate";
import serviceReducer from "./service";
import discountReducer from "./discount";
import vehicleReducer from "./vehicle";
import shopReducer from "./shop";

const rootReducer = combineReducers({
  users: usersReducer,
  permissions: permissionReducer,
  roles: rolesReducer,
  menus: menusReducer,
  address: addressReducer,
  paymentTerms: paymentTermsReducer,
  paymentTypes: paymentTypesReducer,
  customers: customersReducer,
  option: optionReducer,
  estimate: estimateReducer,
  service: serviceReducer,
  discount: discountReducer,
  vehicle: vehicleReducer,
  shop: shopReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
